import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";

import { blobToBase64 } from "src/app/shared/utils/text";
import {
  WAService,
  WAContentType,
} from "src/app/shared/components/send-to-wa/send-to-wa.service";

export interface ModalPDFViewerConfig {
  blob: Blob;
  filename: string;
  title: string;
  modalClass?: string;
  waNumbers?: string[];
  waText?: string;
}

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.css"],
  providers: [WAService],
})
export class PdfViewerComponent implements OnInit {
  private config: ModalPDFViewerConfig;
  private resolve: any;
  private reject: any;

  public src: Blob;
  public showToolbar: boolean = false;

  constructor(private modal: BsModalRef, private waService: WAService) {}

  ngOnInit(): void {}

  setOption(resolve, reject, config: ModalPDFViewerConfig): void {
    this.config = config;
    this.resolve = resolve;
    this.reject = reject;

    this.src = config.blob;
  }

  onPageRendered(event): void {
    this.showToolbar = true;
  }

  hide(): void {
    this.resolve();
    this.modal.hide();
  }

  sendToWA(): void {
    blobToBase64(this.config.blob)
      .then((base64String) => {

        const base64 = base64String.split(",")[1];

        this.waService.sendToWA(this.config.waNumbers || [], WAContentType.MEDIA, {
          mimetype: "application/pdf",
          filename: this.config.filename,
          value: base64,
          text: this.config.waText
        });
      })
      .catch((error) => {
        console.error("Erro:", error);
      });
  }
}
