<div class="modal-content">
  <div class="modal-header"><span><i class="fab fa-whatsapp"></i> Enviar para Whatsapp</span></div>
  <div class="modal-body">
    <div class="row">

      <div class="col-12" *ngIf="!!fileName">
        <p><strong>Arquivo: </strong> {{ fileName }}</p>
      </div>

      <hr>

      <div class="col alert alert-warning m-3" *ngIf="!sessions || sessions?.length == 0">
        <p>Nenhuma configuração de envio encontrada!</p>
        <p><em>Entre em contato com o suporte!</em></p>
      </div>
      <div class="form-group col-md-12" *ngIf="sessions?.length > 1">
        <label class="col-form-label" for="session">Enviar Via:</label>
        <select class="form-control" id="session" placeholder="Módulos" (ngModelChange)="setSession($event)"
          [(ngModel)]="selectedSession">
          <option [ngValue]="null">Selecione</option>
          <option *ngFor="let session of sessions" [ngValue]="session">{{ session.session_name }}</option>
        </select>
      </div>
      <div class="form-group col-md-12" *ngIf="sessions?.length > 0">
        <label class="col-form-label" for="phone">Enviar Para:</label>
        <input type="tel" class="form-control" [(ngModel)]="phone">
      </div>
      <div class="form-group col-md-12" *ngIf="sessions?.length > 0">
        <label class="col-form-label" for="text">Texto:</label>
        <textarea class="form-control" name="text" rows="2" [(ngModel)]="text"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="!valid()" (click)="done($event)"
      *ngIf="sessions?.length > 0">OK</button>
    <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">Cancelar</button>
  </div>
</div>
